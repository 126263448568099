import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */
class Impressum extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
  
    /**
     * @returns The rendered Impressum component
     */
    render() {
      return (
        <div className="impressum">
          <h1>Impressum</h1>
          <p>Projekt für den Lehrstuhl <a href="https://www.uni-bamberg.de/en/cogsys/">Cognitive Systems</a> der Universität Bamberg</p> 
          <p>Autoren:</p>
          <ul>
              <li>Elena Giacomazzi (elena-sophie.giacomazzi@stud.uni-bamberg.de)</li>
              <li>Franziska Mäckel (franziska.maekel@stud.uni-bamberg.de)</li>
              <li>Leon Fruth (leon-pascal.fruth@stud.uni-bamberg.de)</li>
              <li>Eva Jansohn (eva-katharina.jansohn@stud.uni-bamberg.de)</li>
          </ul>
        </div>
      );
    }
  }
  
  export default withRouter(Impressum);
  Impressum.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };