
import analogyTexts from "../../resources/analogyTexts.json";

/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */
export function getText(
  analogy,
  analogyTextIndex,
  correct,
  beginningBoolean,
  endBoolean,
  analogySubPanelVisibilityParam
) {
  let beginningAnalogy= beginningBoolean;
  let endAnalogy = endBoolean;
  let analogySubPanelVisibility = analogySubPanelVisibilityParam;
  let styling = [0, 0, 0, 0, 0]; 
    let updateCorrectionsAndResultObj;

//arrays for corrections and reults
const arrayCross = [false, false, false, false, false];
const arrayCorrection = [NaN, NaN, NaN, NaN, NaN];
const arrayResult = [NaN, NaN, NaN, NaN, NaN];

  // helper Variables
  let tempCorrectionStep = null;
  let einerIndex = analogy.min.length - 1;
  let zehnerIndex = analogy.min.length - 2;
  let hunderterIndex = analogy.min.length - 3;
  let tausenderIndex = analogy.min.length - 4;
  let zehntausenderIndex = analogy.min.length - 5;
  // variables to create correct texts
  let digit = analogyTexts.digits;
  let digit_with_n = analogyTexts.digits_with_n;

  let text = {
    correct: analogyTexts.correct,
    noCorrection1digit: analogyTexts.noCorrection1digit,
    noCorrectionMoredigits: analogyTexts.noCorrectionMoredigits,
    withCorrectionEinerstelleFalse: analogyTexts.withCorrectionEinerstelleFalse,
    withCorrectionAbZehnerFalse: analogyTexts.withCorrectionAbZehnerFalse,
    
    analogy: analogyTexts.analogy,
    //INCLUDE TAUSENDER UND HUNDERTERSTELLE
  };
  // returns text to display while changing the helper variables to the correct state
  let stateArray = getTextStateArray();
 
  // object to return to the main class to adjust variables accordingly
  let returnObject = {
    
    updateCorrectionsAndResultObj: stateArray[analogyTextIndex].upResObj,
    correct: correct,
    text: stateArray[analogyTextIndex].text,
    endAnalogy: stateArray[analogyTextIndex].endAnalogy,
    beginningAnalogy: stateArray[analogyTextIndex].beginningAnalogy,
    analogySubPanelVisibility: stateArray[analogyTextIndex].anSubPanel,
    styling: stateArray[analogyTextIndex].styling,
    einerIndex: einerIndex,
    zehnerIndex: zehnerIndex,
    hunderterIndex: hunderterIndex,
    tausenderIndex: tausenderIndex,
    zehntausenderIndex: zehntausenderIndex,
    analogy: analogy,

  };



  return returnObject;
  // produces following text
  //" Wir fangen bei den Einern an und rechnen Minuend[einerIndex] - Subtrahend[einerIndex] = Ergebnis[einerIndex]."
  function returnStringStartEinerstelleWithoutCorrection() {
    
    return (
      
      text.withCorrectionAbZehnerFalse[0] +
      digit_with_n[0] +
      text.withCorrectionAbZehnerFalse[1] +
      analogy.min[einerIndex] +
      "\u00a0-\u00a0" +
      analogy.sub[einerIndex] +
      "\u00a0=\u00a0" +
      analogy.res[einerIndex] +
      "."
    );
  }

  // Function to return the String of "Jetzt rechnen wir die stellestelle mit min-sub=res."
  // with inputs at which column (stelle) the calculation of the corresponding min-sub=res happens
  function returnStringNoCorrection(stelle, min, sub, res) {
    var string =
      text.noCorrectionMoredigits[0] +
      stelle +
      text.noCorrectionMoredigits[1] +
      min +
      "\u00a0-\u00a0" +
      sub +
      "\u00a0=\u00a0" +
      res +
      ".";
    return string;
  }
  // Produces followin text
  // "Wir fangen bei der Einerspalte ganz rechts an.
  // Wir könne nicht einfach min[einerIndex]-sub[einerIndex] rechnen, da min[einerIndex] kleiner als sub[einerIndex] ist."
  function returnStringStartEinerstelle() {
    var string1WithCorrection2Digits =
      text.withCorrectionEinerstelleFalse[0] +
      analogy.min[einerIndex] +
      "\u00a0-\u00a0" +
      analogy.sub[einerIndex] +
      text.withCorrectionEinerstelleFalse[1] +
      analogy.min[einerIndex] +
      text.withCorrectionEinerstelleFalse[2] +
      analogy.sub[einerIndex] +
      text.withCorrectionEinerstelleFalse[3];
    return string1WithCorrection2Digits;
  }
  // Produces following text
  // "Weiter geht es in der Zehnerzeile. Wir können nicht einfach min-sub[zehnerIndex] rechnen,
  // da min kleiner als sub[zehnerIndex] ist."
  function returnStringAbZehnerstelle(stelle, index, min) { //CHANge MIN ????
    
    var string =
      text.withCorrectionAbZehnerFalse[2] +
      digit[stelle] +
      text.withCorrectionAbZehnerFalse[3] +
      min +
      "\u00a0-\u00a0" +
      analogy.sub[index] +
      text.withCorrectionAbZehnerFalse[4] +
      min +
      text.withCorrectionAbZehnerFalse[5] +
      analogy.sub[index] +
      text.withCorrectionAbZehnerFalse[6];
    return string;
  }
  // Produces the following lines of text:
  // "Wir müssen uns 10 von der stelle2stelle leihen. Wir ziehen 1 von den stelle2n ab,
  // also min[index2]-1=cor[index2], und erhalten dafür 10 stelle1, also min[index1]+10=cor[index1]."
  function returnStringCorrectionStep(
    stelle1,
    stelle2,
    index1,
    index2,
    min,
    cor,
    special = false,
    specialHunderter = false
  ) {
    if (special) {
      var corIndex2 = cor[index2] - 10;
      
    } else {
      corIndex2 = cor[index2];
    }
    
    if (specialHunderter) {
      var minIndex1 = cor[index1] - 10;
    } else {
      minIndex1 = min[index1];
    }
   
    var string2WithCorrection2Digits =
      text.withCorrectionEinerstelleFalse[4] + 
      stelle2 +
      text.withCorrectionEinerstelleFalse[5] +
      stelle2 +
      "n " +
      text.withCorrectionEinerstelleFalse[6] +
      min[index2] +
      text.withCorrectionEinerstelleFalse[7] +
      corIndex2 +
      text.withCorrectionEinerstelleFalse[8] +
      stelle1 +
      text.withCorrectionEinerstelleFalse[9] +
      minIndex1 +
      text.withCorrectionEinerstelleFalse[10] +
      cor[index1] + //HERE??
      ".";
    return string2WithCorrection2Digits;
  }
  // Produces the following lines of text:
  // Tadaaa! Wir haben unser Ergebnis. Probiere es gleich nochmal mit deiner Aufgabe.
  function returnStringFinalResult() {
    return text.analogy[2];
  }

  function updateCorrectionsAndResult(
    arrayCross,
    arrayCorrection,
    arrayResult
  ) {
    // create update object to return to main class
  
    updateCorrectionsAndResultObj = {
      
      crossZehntausender: typeof arrayCross[4] === "undefined"
      ? false
      : arrayCross[4],
      crossTausender: typeof arrayCross[3] === "undefined"
      ? false
      : arrayCross[3],
      crossHunderter: typeof arrayCross[2] === "undefined"
      ? false
      : arrayCross[2],
      crossZehner: typeof arrayCross[1] === "undefined"
      ? false
      : arrayCross[1],
      crossEiner: typeof arrayCross[0] === "undefined"
      ? false
      : arrayCross[0],
      corZehntausender: typeof arrayCorrection[4] === "undefined"
      ? NaN
      : arrayCorrection[4],
      corTausender: typeof arrayCorrection[3] === "undefined"
      ? NaN
      : arrayCorrection[3],
      corHunderter: typeof arrayCorrection[2] === "undefined"
      ? NaN
      : arrayCorrection[2],
      corZehner: typeof arrayCorrection[1] === "undefined"
      ? NaN
      : arrayCorrection[1],
      corEiner: typeof arrayCorrection[0] === "undefined"
      ? NaN
      : arrayCorrection[0],
      resZehntausender: typeof arrayResult[4] === "undefined"
      ? NaN
      : arrayResult[4],
      resTausender: typeof arrayResult[3] === "undefined"
      ? NaN
      : arrayResult[3],
      resHunderter: typeof arrayResult[2] === "undefined"
      ? NaN
      : arrayResult[2],
      resZehner: typeof arrayResult[1] === "undefined"
      ? NaN
      : arrayResult[1],
      resEiner: typeof arrayResult[0] === "undefined"
      ? NaN
      : arrayResult[0],
      
   
    };
    updateCorrectionsAndResultObj = JSON.parse(
      JSON.stringify(updateCorrectionsAndResultObj)
    );
    
    return updateCorrectionsAndResultObj;
  }

  function returnLeihenVonNull(
    stelle
  ){
    var special_text_1 =
    analogyTexts.special_case_3[0] +
    digit[stelle] +
    analogyTexts.special_case_3[1]+
    digit_with_n[stelle+1] +
    analogyTexts.special_case_3[2]+
    analogyTexts.special_case_3[3]+
    digit_with_n[stelle+1]+
    analogyTexts.special_case_3[4];

    return special_text_1;

  }

  function returnAdditionalLeihenVonNull(
    stelle
    ){
     var  special_text_2 =
     analogyTexts.special_case_4[0] +
     digit[stelle] +
      analogyTexts.special_case_4[1] +
     digit_with_n[stelle + 1] + 
      analogyTexts.special_case_4[2]+
      
      analogyTexts.special_case_3[3]+
      digit_with_n[stelle+1]+
      analogyTexts.special_case_3[4];

      ;

      return special_text_2;
  
  }

  function returnLeihenMitNull(
    stelle, index, special
    ){
      if (special) {
        var corIndex3 = analogy.cor[index-1] - 10;
        
      } else {
        var corIndex3 = analogy.cor[index-1];
      }
     var  special_text_3 =
     analogyTexts.special_case_4[0] +
     digit[stelle -1] +
      analogyTexts.special_case_4[1] +
      digit_with_n[stelle ] +
      analogyTexts.special_case_4[2]+
      analogyTexts.special_case_4[3] +
      analogy.min[index -1 ] + 
      analogyTexts.special_case_4[4] +
      corIndex3 +
      analogyTexts.special_case_4[5] +
      digit[stelle-1 ]+
      analogyTexts.special_case_4[6]+
      digit_with_n[stelle-1]+
      analogyTexts.special_case_4[7];


      return special_text_3;
  
  }

  function returnFertigLeihenMitNull(
    stelle, index
    ){
     if(analogy.cor[index +1] == 9 && analogy.min[index +1] == 0){
        var corIndex5 = 0;
        var corIndex6 = 10;
     }
     else{
      var corIndex5 = analogy.cor[index +1] - 10;
      var corIndex6 = analogy.cor[index+1];
     }

     
        
      
     var  special_text_4 =
     analogyTexts.special_case_5[0]+
     digit_with_n[stelle-1]+
     analogyTexts.special_case_5[1]+
     digit[stelle]+
     analogyTexts.special_case_5[2]+
     analogy.cor[index]+
     analogyTexts.special_case_5[3]+
     digit[stelle -1]+     
     analogyTexts.special_case_5[4]+
     corIndex5+     
     analogyTexts.special_case_5[5]+
     corIndex6+
     analogyTexts.special_case_5[6];

     //not sure if order is right
     return special_text_4;
  
  }


 
  function getTextStateArray(){
    var output;
   
   
    
        var special1 = false;
        var special2 = false;
        var digit_counter = 0; //count digitits in output
        var textForExport = [];
        if(!correct){
        for(let i = analogy.sub.length - 1; i >= -1 ; i--){
         
             //set styling back to 0
          styling = [0,0,0,0,0];
          special1 = false;
          special2 = false;
        

            if(i == analogy.sub.length - 1){
                
                //first slide of introduction
                beginningAnalogy = true;
                endAnalogy = false;
                analogySubPanelVisibility = "hidden";
      
                //returntext = text.analogy[0];
             
  
                textForExport.push({text: text.analogy[0],beginningAnalogy: true, endAnalogy: false,  upResObj: updateCorrectionsAndResult([], [], []), anSubPanel: analogySubPanelVisibility, styling: styling});
                //second slide of introduction
                beginningAnalogy = false;
                
                //returntext = text.analogy[1];
           
                textForExport.push({text: text.analogy[1], endAnalogy: false, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), anSubPanel: analogySubPanelVisibility, styling: styling});
                //analogySubPanelVisibility = "visible";
                

            }         
            
            //if last number in analogy
            if (i == -1){

                //make text for final result
                output = returnStringFinalResult();

                //update arrow
                endAnalogy = true;
                let emptyUpResObj =  {
      
                  crossZehntausender: false,
                  crossTausender: false,
                  crossHunderter: false,
                  crossZehner: false,
                  crossEiner: false,
                  corZehntausender: NaN,
                  corTausender: NaN,
                  corHunderter: NaN,
                  corZehner: NaN,
                  corEiner: NaN,
                  resZehntausender: NaN,
                  resTausender: NaN,
                  resHunderter: NaN,
                  resZehner: NaN,
                  resEiner: NaN,
                };
                //figure out what to do with output
               // returntext = output;
               
               textForExport.push({text: output, endAnalogy: true, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling});
               break;
            }
            //when correction is bigger than minuend or subtrahend is less than minuend (no corrections neccesarry)
	        else if((  arrayCross[digit_counter] == false && analogy.sub[i] <= analogy.min[i]) || (arrayCross[digit_counter] == true && analogy.sub[i] <= arrayCorrection[digit_counter])){

                //introduce the very first number
                if(i == analogy.sub.length - 1){
                    //create output text
                    output = returnStringStartEinerstelleWithoutCorrection();

                    //update corrections and results
                    arrayResult[digit_counter] = analogy.res[i];
                    let temp = updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult);

                    //update styling
                    //styling = i === 2 ? [0, 0, 0, 0, 1] : [0, 0, 0, 1, 0];
                    

                   // returntext = output;
                  
                }                
                //create string if suvbtrahend has been decramemted
                else if( arrayCross[digit_counter] == true){
                    output = returnStringNoCorrection(
                        digit[digit_counter],
                        analogy.cor[i],
                        analogy.sub[i], 
                        analogy.res[i]
                    );
                }
                //create string when subtrahend hasn't been decramented
                else{
                output = returnStringNoCorrection(
                    digit[digit_counter],
                    analogy.min[i],
                    analogy.sub[i],
                    analogy.res[i]
                );
                }

            //update result in corrections and results
              arrayResult[digit_counter] = analogy.res[i];
              let temp = updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult);
                
              //update styling
                styling[i] = 1;
        
                

                //return or print?
                //returntext = output;

                textForExport.push({text: output, endAnalogy:false, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling});
            }            
            //correction needed
            else{

                if(i == analogy.sub.length - 1){
                  styling[0,0,0,0,0];
                   
                 
                    output = returnStringStartEinerstelle();

                    styling[i] = 1;
           
                    textForExport.push({text: output, endAnalogy: false, upResObj: updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult), styling: styling});

                }
                else{//} if(analogy.min[i-1] != 0){
                  styling[0,0,0,0,0];
                  //make introduction
                  output = returnStringAbZehnerstelle(
                    digit_counter,
                    i,
                    analogy.cor[i] - 10
                  );
                
                  
                  
                  //update analogy
                 /* tempCorrectionStep =
                  Number(analogy.cor[i]) > 9
                    ? analogy.cor[i] - 10
                    : analogy.cor[i];
                 */
                  //update corrections and results
                 // arrayCross[digit_counter]= false; CHANGE TO SPECIAL CODITION???????????
                 // arrayCorrection[digit_counter] = tempCorrectionStep;
                  //ISCORRECTION FIXED?
                    let temp= updateCorrectionsAndResult(
                      arrayCross,
                      arrayCorrection,
                      arrayResult
                    );
                    styling[i] = 1;
                  //update styling
                 
                     
                 // returntext = output;
                
                 textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});
                }

                if(analogy.min[i-1] == 0){
                  
                    let j  = i ;
                    let temp_digit_counter = digit_counter + 1;
                    output = returnLeihenVonNull(digit_counter);
                    let temp1= updateCorrectionsAndResult(
                      arrayCross,
                      arrayCorrection,
                      arrayResult
                    );
                    styling = [0,0,0,0,0];
                    styling[i] = 1;
                    styling[i-1] = 1;
                    textForExport.push({text: output, endAnalogy : false, upResObj: temp1, styling: styling});
                 
                    if((analogy.min[j-2] == 0)){
                    while(analogy.min[j-2] == 0){
                     
                      styling=[0,0,0,0,0];
                      output = returnAdditionalLeihenVonNull(temp_digit_counter);
                      let temp= updateCorrectionsAndResult(
                        arrayCross,
                        arrayCorrection,
                        arrayResult
                      );
                    

                      styling[j-1] = 1;
                      styling[j-2] = 1; 
                      textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});
                      j--;

                      temp_digit_counter++;
                    }
                  }
                  
                    j--;
                    temp_digit_counter++;
                  
                
                    
                    var special_case = false;
                    if(analogy.cor[j-1] > 9){
                      special_case = true;
                    }
                    output = returnLeihenMitNull(temp_digit_counter, j, special_case);
                    styling = [0,0,0,0,0];
                    styling[j-1] = 1;
                    styling[j]= 1;
                    arrayCross[temp_digit_counter] = true;
                    //arrayCross[temp_digit_counter - 1] = true;
                    
                    if(special_case){
                    arrayCorrection[temp_digit_counter] = analogy.cor[j-1] - 10;//???????????
                    }
                    else{
                      arrayCorrection[temp_digit_counter] = analogy.cor[j-1];
                    }
                    arrayCorrection[temp_digit_counter-1] = 10;
                    let temp= updateCorrectionsAndResult(
                      arrayCross,
                      arrayCorrection,
                      arrayResult
                    );
                    textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});
                   
                    while(j != i){
                     
                      temp_digit_counter--;
                      styling = [0,0,0,0,0];
                      if(analogy.cor[j-1] > 9){
                        special_case = true;
                      }
                      output = returnFertigLeihenMitNull(temp_digit_counter, j);
                      
                      /*tempCorrectionStep =
                      Number(analogy.cor[j+1]) > 9
                        ? analogy.cor[j+1] - 10
                        : analogy.cor[j+1];
                     */
                      //update corrections and results
                      arrayCross[temp_digit_counter]= true; //????????????????
                      if(j + 1 != 0){
                      arrayCorrection[temp_digit_counter] = 9;
                      }
                      else{
                        arrayCorrection[temp_digit_counter] = 10;
                      }
                      arrayCorrection[temp_digit_counter-1] = analogy.cor[j+1]; //??????????
    
    
                          let temp= updateCorrectionsAndResult(
                            arrayCross,
                            arrayCorrection,
                            arrayResult
                          );
                          styling[j]=1;
                          styling[j+1] = 1;
                          textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});
                      //return back of case 4????
                      //temp_digit_counter--;
                      j++;
                    }
                  

                   // output = returnFertigLeihenMitNull(digit_counter, i);
                }
                else{
                  
                    //create output string about correction
                    if(analogy.min[i - 1] < analogy.sub[i-1]){
                      special1 = true;
                    }
                    if(arrayCross[digit_counter] == true){ //is this correct????
                      special2 = true;
                    }
                        output = returnStringCorrectionStep( //when is it not special????
                        digit[digit_counter], 
                        digit[digit_counter+ 1],
                        i,
                        i - 1,
                        analogy.min,
                        analogy.cor,
                        special1,
                        special2
                      
                      );
                    
                      //update correction and reult
                      //arrayCross[digit_counter] = true;
                      arrayCross[digit_counter+1] = true;
                      //if(analogy.cor[i+1])
                      arrayCorrection[digit_counter] = analogy.cor[i]; //becuase backwards???
                     
                      arrayCorrection[digit_counter+1] = analogy.cor[i - 1]; //becuase backwards????
                      if(analogy.cor[i-1] >= 10){
                        arrayCorrection[digit_counter+1] -= 10;
                      }
                      let temp = updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult);

                      //update styling
                      styling[i-1] = 1;

                      //update arrows
                      beginningAnalogy = false;
                      endAnalogy = false;

                      //figure out what to do with output??????
                    //  returntext = output;
                    
                    textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});

               }

               //text for subtraction with adjusted subtrahend
               output = returnStringNoCorrection(
                digit[digit_counter],
                analogy.cor[i],
                analogy.sub[i],
                analogy.res[i]
              );

              //update corrections and reults
              arrayResult[digit_counter] = analogy.res[i];
             let temp= updateCorrectionsAndResult(arrayCross, arrayCorrection, arrayResult);
              styling = [0,0,0,0,0];
              styling[i] = 1;
                //no need to update arrows or styling

              //figure out???
              //returntext = output;
        
              textForExport.push({text: output, endAnalogy : false, upResObj: temp, styling: styling});



        

            }
            digit_counter++;

          
}

return textForExport;

}
else{
  analogySubPanelVisibility = "hidden";
  let emptyUpResObj =  {
      
    crossZehntausender: false,
    crossTausender: false,
    crossHunderter: false,
    crossZehner: false,
    crossEiner: false,
    corZehntausender: NaN,
    corTausender: NaN,
    corHunderter: NaN,
    corZehner: NaN,
    corEiner: NaN,
    resZehntausender: NaN,
    resTausender: NaN,
    resHunderter: NaN,
    resZehner: NaN,
    resEiner: NaN,
  };
  styling = [0,0,0,0,0];
  output = text.correct;
textForExport.push({text: output, endAnalogy : true, upResObj: emptyUpResObj, anSubPanel: analogySubPanelVisibility, styling: styling})
  return textForExport;
}}

 }